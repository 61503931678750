* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 15px;
}

:root {
  --background: #fafafa;
  --red: rgba(242, 5, 64, 0.5);
  --green: #298C5B;
}